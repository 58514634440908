<div class="heading-1 page-title">
  <app-icon-button
    *ngIf="previousPageRouterLink && previousPageTitle"
    [iconButtonRouterLink]="previousPageRouterLink"
    [icon]="Icons.NAVIGATION_ARROW_LEFT_BOLD"
    [iconSize]="IconSize.MEDIUM"
    class="page-title-back-button"
    [appTooltip]="'shared.components.ui.page-title.previous-page-button-tooltip' | translate: { previousPageTitle }"
  ></app-icon-button>
  <h1 *ngIf="heading" class="page-title-heading" data-testid="page-title">
    {{ heading }}
  </h1>
</div>
